<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>考务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage' }">个人赛考务工具</el-breadcrumb-item>
      <el-breadcrumb-item>表单答题管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <div class="title">{{ seasonStr(queryForm.season) }}{{ raceScheduleStr(queryForm.raceSchedule) }}</div>
      <div class="title-flex" v-if="queryForm.raceSchedule === 19 && queryForm.level !== ''">
        <div>{{levelStr(queryForm.level)}}总人数：<span>{{ totalNum }}</span></div>
        <div>已签到：<span>{{ signNum }}</span></div>
        <div>未签到：<span>{{ notSignNum }}</span></div>
        <el-button type="primary" size="small" plain style="margin-left: 10px;" @click="showNoSignIn = true">查看未签到选手</el-button>
      </div>
    </el-card>
    <!-- 筛选 -->
    <el-row :gutter="15">
      <el-col :span="18">
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">信息筛选</span>
            </el-col>
          </el-row>
          <el-form
            :model="queryForm"
            class="filter_Form"
            label-width="70px"
            ref="queryFormRef"
          >
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="赛季" prop="season">
                  <el-select
                    placeholder="请选择赛季"
                    style="width: 100%"
                    v-model="queryForm.season"
                  >
                    <el-option
                      v-for="item in dict_season"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="赛区" prop="districtId">
                  <el-select
                    placeholder="请选择赛区"
                    style="width: 100%"
                    clearable
                    v-model="queryForm.districtId"
                  >
                    <el-option
                      v-for="item in dict_district"
                      :key="item.id"
                      :label="item.districtName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="赛程" prop="raceSchedule">
                  <el-select
                    v-model="queryForm.raceSchedule"
                    placeholder="请选择赛程"
                    style="width: 100%"
                    ><el-option
                      v-for="item in raceScheduleList"
                      :key="item.scheduleSort"
                      :label="item.scheduleName"
                      :value="Number(item.id)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="queryForm.raceSchedule === 2">
                <el-form-item label="参赛码" prop="matchCode">
                  <el-input
                    v-model="queryForm.matchCode"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="queryForm.name"
                    placeholder="请输入姓名"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    v-model="queryForm.phone"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="组别" prop="level">
                  <el-select
                    v-model="queryForm.level"
                    placeholder="请选择组别"
                    style="width: 100%"
                    @change="search"
                    >
                    <el-option
                      v-for="item in dict_group"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="queryForm.raceSchedule === 2 ? 16 : 24" align="right">
                <el-button
                  type="primary"
                  @click="search">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col :span="12">
              <span class="title_class">大屏控制</span>
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <el-tag v-if="tagStr" :type="tagType" size="mini">{{ tagStr }}</el-tag>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="margin-bottom: 14px;">
              <el-button :disabled="!queryForm.matchCode" type="primary" plain round @click="handleToChangeStatus(3)" :icon="queryForm.matchCode ? 'el-icon-loading' : 'el-icon-refresh'">成绩统计中</el-button>
            </el-col>
            <el-col :span="24" style="margin-bottom: 14px;">
              <el-button :disabled="!queryForm.matchCode" type="primary" plain round @click="handleToChangeStatus(4)" icon="el-icon-finished">发布成绩</el-button>
            </el-col>
            <el-col :span="24" style="margin-bottom: 14px;">
              <el-button :disabled="!queryForm.matchCode" type="primary" plain round @click="copyScreenLink" icon="el-icon-link">复制大屏链接</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="6">
          <span class="title_class">表单答题成绩</span>
        </el-col>
        <el-col :span="18" style="text-align: right;">
          <el-button type="primary" plain @click="showAddedScore" v-if="queryForm.raceSchedule === 19">计算附加分</el-button>
          <el-button type="primary" plain @click="showRevive" v-if="queryForm.raceSchedule === 19">设置复活赛资格</el-button>

          <el-button type="primary" @click="showMatch" v-if="queryForm.raceSchedule !== 19">一键匹配至线下成绩</el-button>
          <el-button type="primary" @click="exportFn">导出成绩</el-button>
          <el-button type="primary" @click="setPassDialogVisible = true" v-if="queryForm.raceSchedule !== 19">批量设置晋级</el-button>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛区" prop="districtName" width="90">
          <template slot-scope="scope">
            {{scope.row.district.districtName}}
          </template>
        </el-table-column>
        <el-table-column label="赛程" prop="raceSchedule" :formatter="raceScheduleFormatter"></el-table-column>
        <el-table-column label="参赛码" prop="matchCode" v-if="queryForm.raceSchedule === 2"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="手机号" prop="phone" width="100px"></el-table-column>
        <el-table-column label="性别" prop="sex">
          <template slot-scope="scope">
            {{ scope.row.sex == 1 ? '男' : scope.row.sex == 0 ? '女' : '未设置' }}
          </template>
        </el-table-column>
        <el-table-column label="签到时间" prop="signTime" width="100" v-if="queryForm.raceSchedule === 19"></el-table-column>
        <el-table-column label="分数" prop="score"></el-table-column>
        <el-table-column label="答题时长" prop="times" :formatter="formatSeconds" width="100" v-if="queryForm.raceSchedule === 19"></el-table-column>
        <el-table-column label="附加分" prop="addesScore" v-if="queryForm.raceSchedule === 19"></el-table-column>
        <el-table-column label="复活赛资格" prop="reviveRound" v-if="queryForm.raceSchedule === 19">
          <template slot-scope="scope">
            <div v-if="scope.row.tbEnrollment.reviveRound">
              第{{ scope.row.tbEnrollment.reviveRound }}轮
            </div>
          </template>
        </el-table-column>
        <el-table-column label="是否晋级" prop="promotion">
          <template slot-scope="scope">
            <el-tag size="mini" :type="(scope.row.tbEnrollment) ?
             ((scope.row.tbEnrollment.promotion === 1)
             ? 'success' : (scope.row.tbEnrollment.promotion === 2
             ? 'danger' : 'info')) :
             'info'">{{ (scope.row.tbEnrollment)
             ? ((scope.row.tbEnrollment.promotion === 1)
             ? '晋级' : (scope.row.tbEnrollment.promotion === 2
             ? '未晋级' : '未设置')) : '未设置' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="系统匹配" prop="userId" v-if="queryForm.raceSchedule !== 19">
          <template slot-scope="scope">
            <el-tag size="mini" :type="(scope.row.userId && scope.row.userId !== '') ? 'success' : 'danger'">{{ (scope.row.userId && scope.row.userId !== '') ? '已匹配' : '未匹配' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="交卷时间" prop="submitTime" width="100" v-if="queryForm.raceSchedule === 19"></el-table-column>
        <el-table-column label="学校" prop="school" width="180"></el-table-column>
        <el-table-column label="年级" prop="grade" :formatter="gradeFormatter"></el-table-column>
        <!-- <el-table-column label="班级" prop="classes"></el-table-column> -->
        <el-table-column label="操作" width="120px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showDetail(scope.row)">查看详情</el-button>
            <template v-if="queryForm.raceSchedule !== 19">
              <el-button type="text" size="small"  v-if="scope.row.userId && scope.row.userId !== ''" @click="untieDetail(scope.row)">解绑</el-button>
              <el-button type="text" size="small" v-else @click="normalDetail(scope.row)">匹配</el-button>
            </template>
            <!-- <el-button type="text" size="small" @click="showInfo(scope.row)">修改选手信息</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 未签到选手 -->
    <el-dialog
      :visible.sync="showNoSignIn">
      <div slot="title">
        <div style="text-align: center; font-size: 18px;">未签到选手列表</div>
        <div class="title-flex title-flex1">
        <div>{{levelStr(queryForm.level)}}总人数：<span>{{ totalNum }}</span></div>
        <div>已签到：<span>{{ signNum }}</span></div>
        <div>未签到：<span>{{ notSignNum }}</span></div>
        <el-button type="primary" size="small" plain style="margin-left: 10px;" @click="noSignInFn">刷新</el-button>
      </div>
      </div>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="noSignInList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="英文名" prop="enName"></el-table-column>
        <el-table-column label="比赛号码牌" prop="nationalnum"></el-table-column>
        <el-table-column label="抽签顺序号" prop="competitionNumber"></el-table-column>
        <el-table-column label="手机号" prop="phone" width="100px"></el-table-column>
        <el-table-column label="性别" prop="sex">
          <template slot-scope="scope">
            {{ scope.row.sex == 1 ? '男' : scope.row.sex == 0 ? '女' : '未设置' }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 一键匹配 -->
    <el-dialog
      title="选手信息匹配"
      :visible.sync="matchVisible"
      width="400px">
      <el-form ref="matchFormRef" :model="matchForm" :rules="matchFormRules" label-width="80px">
        <el-form-item label="赛程" prop="raceSchedule">
          <el-select v-model="matchForm.raceSchedule" placeholder="请选择" style="width: 100%;">
            <el-option
                  v-for="item in dict_matchType"
                  :key="item.id"
                  :label="item.scheduleName"
                  :value="item.id"
                ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赛区" prop="districtId">
          <el-select v-model="matchForm.districtId" placeholder="请选择" style="width: 100%;">
            <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="matchVisible = false">取 消</el-button>
        <el-button type="primary" @click="matching">开始匹配至线下成绩</el-button>
      </span>
    </el-dialog>
    <!-- 修改选手信息 -->
    <el-dialog
      title="修改选手信息"
      :visible.sync="infoVisible"
      width="500px">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="editForm.sex">
            <el-radio :label="0">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学校" prop="school">
          <el-input v-model="editForm.school"></el-input>
        </el-form-item>
        <el-form-item label="年级" prop="grade">
          <el-select v-model="editForm.grade" placeholder="请选择活动区域" style="width: 100%;">
            <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="班级" prop="classes">
          <el-input v-model="editForm.classes"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      title="作答详情"
      :visible.sync="dialogVisible"
      width="1100px">
      <el-row>
        <el-col :span="8"><div class="total">姓名：<span>{{ userName }}</span></div></el-col>
        <el-col :span="8"><div class="total">电话：<span>{{ phone }}</span></div></el-col>
        <el-col :span="8"><div class="total">成绩：<span>{{ totalScore }}</span></div></el-col>
      </el-row>
      <template v-if="queryForm.raceSchedule == 19">
        <div class="default" v-if="!resultList.length">
          暂无作答记录
        </div>
        <div class="question-item" v-for="(item, index) in resultList" :key="index">
          <div class="a-title">
            <el-tag type="primary" size="small" class="tag">Part {{ index+1 }}</el-tag>
            <el-tag type="success" size="small" class="tag">{{ item.questionType }}</el-tag>
            <span class="text">{{ item.description }}</span>
          </div>
          <div class="question-title" v-if="item.title">{{ item.title }}</div>
          <img class="img-desc" v-if="item.imageDdescription" :src="item.imageDdescription" alt="">
          <div class="questionDdescription" v-if="item.questionDdescription" v-html="item.questionDdescription"></div>
          <el-table header-cell-class-name="tableHeaderStyle" :data="item.type !== 'choiceSpell' ? item.questions : item.question.answers" stripe border>
            <el-table-column label="题号" :width="item.type !== 'choiceSpell' ? '150px' : ''">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.example" effect="dark" type="primary">例题</el-tag>
                <div v-else-if="scope.row.sort">{{ scope.row.sort }}</div>
              </template>
            </el-table-column>
            <el-table-column label="题目" prop="selval" v-if="item.type !== 'choiceSpell'">
              <template slot-scope="scope">
                <div v-if="item.type === 'singleChoice'">
                  <div class="question mb-6" v-if="scope.row.questionType === 'text'">{{ scope.row.question }}</div>
                  <img class="question-img" v-else-if="scope.row.questionType === 'image'" :src="scope.row.question" alt="">
                  <div class="flex">
                    <div class="opt" v-for="(opt, j) in scope.row.options" :key="j">
                      <div class="opt-index">{{ opt.option }}.</div>
                      <div>
                        <img class="opt-img" v-if="scope.row.optionType === 'image'" :src="opt.definition" alt="">
                        <div v-else>
                          {{ opt.definition }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="question" v-else-if="item.type === 'judge'">
                  {{ scope.row.question }}
                </div>
                <div v-else-if="item.type === 'line'">
                  <img class="opt-img" :src="scope.row.imageUrl">
                </div>
              </template>
            </el-table-column>
            <el-table-column label="正确答案" prop="answer" :width="item.type !== 'choiceSpell' ? '90px' : ''" align="center">
              <template slot-scope="scope">
                <div v-if="item.type === 'judge'">
                  <el-tag type="success" size="small" effect="dark" v-if="scope.row.answer === 'true'">Yes</el-tag>
                  <el-tag type="warning" size="small" effect="dark" v-else>No</el-tag>
                </div>
                <div v-else>{{ scope.row.answer }}</div>
              </template>
            </el-table-column>
            <el-table-column label="用户答案" prop="userAnswer" :width="item.type !== 'choiceSpell' ? '90px' : ''" align="center">
              <template slot-scope="scope">
                <div v-if="item.type === 'judge'">
                  <el-tag type="success" size="small" effect="dark" v-if="scope.row.userAnswer === 'true'">Yes</el-tag>
                  <el-tag type="warning" size="small" effect="dark" v-else>No</el-tag>
                </div>
                <div v-else>{{ scope.row.userAnswer }}</div>
              </template>
            </el-table-column>
            <el-table-column label="得分" prop="getScore" :width="item.type !== 'choiceSpell' ? '90px' : ''" align="center"></el-table-column>
            <el-table-column label="判定" prop="isRight" :width="item.type !== 'choiceSpell' ? '90px' : ''" align="center">
              <template slot-scope="scope" >
                <el-tag v-if="scope.row.isRight !== undefined" :type="scope.row.isRight ? 'success' : 'danger'" size="mini">{{scope.row.isRight ? '正确' : '错误'}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-else>
        <div v-for="(item,index) in questionListData" :key="index">
          <div class="h1">{{ item.questionData.showName }}</div>
          <el-table header-cell-class-name="tableHeaderStyle" :data="item.questionList" stripe border>
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column label="题目">
              <template slot-scope="scope">
                <template v-if="scope.row.questionType === 'FORM_BLANK_ONE_SLOT'">
                  <span>{{scope.row.questionStr}}</span>
                </template>
                <template v-if="scope.row.questionType === 'FORM_DISORDER'">
                  <span>{{scope.row.disorder}}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="正确答案">
              <template slot-scope="scope">
                  <span>{{scope.row.answer}}</span>
              </template>
            </el-table-column>
            <el-table-column label="用户答案">
              <template slot-scope="scope">
                {{scope.row.userAnswer}}
              </template>
            </el-table-column>
            <el-table-column prop="getScore" label="得分"></el-table-column>
            <el-table-column prop="getScore" label="判定">
              <template slot-scope="scope" >
                <el-tag :type="scope.row.getScore == 0 || scope.row.getScore == 4 ? 'danger' : 'success'" size="mini">{{(scope.row.getScore == 0 || scope.row.getScore == 4) ? '错误' : '正确'}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
        <!-- 批量设置晋级dialog -->
        <el-dialog
      title="批量设置晋级"
      :visible.sync="setPassDialogVisible"
      width="30%"
      @close="setPassDialogClose"
    >
      <el-form
        ref="setPassFormRef"
        :model="setPassForm"
        :rules="setPassFormRules"
        label-width="120px"
      >
      <el-form-item label="参赛码" prop="matchCode">
          <el-input
            v-model="setPassForm.matchCode"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="晋级方式" prop="promotionType">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.promotionType"
          >
            <el-option
              v-for="item in dict_setPassWay"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置值" prop="value">
          <el-input
            v-model="setPassForm.value"
            placeholder="请输入"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="参赛人数" prop="particiPantsNumber">
          <el-input
            v-model="setPassForm.particiPantsNumber"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="已匹配人数" prop="mateNumber">
          <el-input
            v-model="setPassForm.mateNumber"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="未匹配人数" prop="notMateNumber">
          <el-input
            v-model="setPassForm.notMateNumber"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="预期晋级人数" prop="promotionPreviewNumber">
          <el-input
            v-model="setPassForm.promotionPreviewNumber"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="预期晋级分数线"
          prop="expectLine"
          v-if="setPassForm.promotionType === 'promotionPreviewScoreLine'"
        >
          <el-input
            v-model="setPassForm.promotionPreviewScoreLine"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="预期晋级比例" prop="promotionPreviewRate">
          <el-input
            v-model="setPassForm.promotionPreviewRate"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setPassDialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="queryPromotionExpectations"
          >查看晋级预期</el-button
        >
        <el-button type="primary" @click="setPass()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="计算附加分"
      :visible.sync="addedScoreVisible">
      <el-form label-width="70px">
        <el-form-item label="组别" prop="level">
          <el-select
            v-model="level"
            placeholder="请选择组别"
            style="width: 100%"
            >
            <el-option
              v-for="item in dict_group"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addedScoreCalc">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="设置晋级到第一轮复活赛"
      width="400px"
      :visible.sync="reviveVisible">
      <el-form label-width="70px">
        <el-form-item label="组别" prop="level">
          <el-select
            v-model="level"
            placeholder="请选择组别"
            style="width: 200px"
            >
            <el-option
              v-for="item in dict_group"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="晋级类型" prop="level">
          <el-radio-group v-model="radio">
            <el-radio :label="1">人数</el-radio>
            <el-radio :label="2">比例</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="晋级人数" prop="num" v-if="radio === 1">
          <el-input v-model="num" style="width: 200px;"></el-input> 人
        </el-form-item>
        <el-form-item label="晋级比例" prop="rate" v-if="radio === 2">
          <el-input v-model="rate" style="width: 200px;"></el-input> %
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setReviveFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryDistrictPage, queryTheOnlineFormAnswer, questionTypeList, promotionBatchPreview, formPromotionBatch, oneClickMatchingFunction, updateTheOnlineFormAnswerInfo, formMatchResultUnbinding, formMatchResultMate, addedScore, setRevive, nmpnotSignList } from '@/http/api'

export default {
  data () {
    return {
      setPassDialogVisible: false,
      dict_district: [],
      dict_season: [],
      dict_matchType: [],
      dict_grade: this.$userInfo.getGradeData(),
      dict_group: this.$userInfo.dict_group(),
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        districtId: '',
        matchCode: '',
        phone: '',
        name: '',
        level: '',
        matchState: '',
        raceSchedule: 19
      },
      setPassForm: {
        matchCode: '',
        promotionType: '',
        value: null
      },
      setPassFormRules: {
        matchCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        promotionType: [{ required: true, message: '请选择', trigger: 'change' }],
        value: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      dateRange: [],
      dialogVisible: false,
      questionListData: [],
      totalScore: 0,
      userName: '',
      phone: '',
      infoVisible: false,
      editForm: {},
      editFormRules: {
        grade: [{ required: true, message: '请选择', trigger: 'change' }],
        sex: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        school: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      matchVisible: false,
      matchForm: {
        districtId: '',
        raceSchedule: ''
      },
      matchFormRules: {
        districtId: [{ required: true, message: '请选择', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_setPassWay: [
        {
          dictCode: 1,
          dictLabel: '人数',
          dictValue: 'userNum'
        },
        {
          dictCode: 2,
          dictLabel: '分数线',
          dictValue: 'scoreLine'
        }
      ],
      tagType: '',
      tagStr: '',
      resultList: [],
      addedScoreVisible: false,
      level: '',
      reviveVisible: false,
      rate: '80',
      num: '',
      radio: 1,
      noSignInList: [],
      showNoSignIn: false,
      notSignNum: 0,
      signNum: 0,
      totalNum: 0
    }
  },
  created () {
    const code = this.$route.query.code
    if (code) {
      this.queryForm.matchCode = code
      this.setPassForm.matchCode = code
      this.getStatusByCode()
    } else {
      this.queryForm.matchCode = ''
      this.setPassForm.matchCode = ''
    }

    this.getSeason()
    this.getDistrictList()
    this.getQuestionTypeList()
    this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value })
    this.dict_matchType = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.dict_matchType = value })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    seasonStr (season) {
      return this.dict_season.find(item => item.dictValue === season) ? this.dict_season.find(item => item.dictValue === season).dictLabel + '赛季' : ''
    },
    levelStr (level) {
      return this.dict_group.find(item => item.dictValue === level) ? this.dict_group.find(item => item.dictValue === level).dictLabel : ''
    },
    raceScheduleStr (raceSchedule) {
      return this.raceScheduleList.length ? this.raceScheduleList.find(item => Number(item.id) === raceSchedule).scheduleName : ''
    },
    formatSeconds (data) {
      if (data.times) {
        const minutes = Math.floor(data.times / 60)
        const remainingSeconds = data.times % 60
        return `${minutes}分${remainingSeconds}秒`
      } else {
        return ''
      }
    },
    noSignInFn () {
      nmpnotSignList({
        level: this.queryForm.level,
        raceSchedule: this.queryForm.raceSchedule,
        season: this.queryForm.season
      }).then(res => {
        if (res.code === 200) {
          this.noSignInList = res.data.notSignList
          this.notSignNum = res.data.notSignNum
          this.signNum = res.data.signNum
          this.totalNum = res.data.totalNum
        }
      })
    },
    getQuestionTypeList () {
      questionTypeList({ status: true }).then((res) => {
        console.log('questionTypeListData', res.data)
        this.questionTypeListData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
        this.getDataList()
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getDataList () {
      queryTheOnlineFormAnswer({ ...this.queryForm, ...{ pageNum: this.pageIndex, pageSize: this.pageSize } }).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },
    search () {
      this.pageIndex = 1
      this.getDataList()
      if (this.queryForm.level !== '') {
        this.noSignInFn()
      }
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      if (this.queryForm.startMatchTime) {
        delete this.queryForm.startMatchTime
        delete this.queryForm.endMatchTime
        this.dateRange = []
      }
      this.search()
    },
    handleSizeChange (e) {
      this.pageSize = e
      this.getDataList()
    },
    handleCurrentChange (e) {
      this.pageIndex = e
      this.getDataList()
    },
    exportFn () {
      this.$message('导出中...')
      const downLoadUrl = `${this.$http.defaults.baseURL}formMatchResult/export`
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', { ...this.queryForm, ...{ pageNum: this.pageIndex, pageSize: this.pageSize } })
    },
    showMatch () {
      this.matchVisible = true
    },
    matching () {
      this.$refs.matchFormRef.validate(async valid => {
        if (!valid) return
        oneClickMatchingFunction(this.matchForm).then(res => {
          this.search()
          this.matchVisible = false
        })
      })
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    dateChange (e) {
      this.queryForm.startMatchTime = this.$moment(e[0]).format('YYYY-MM-DD HH:mm:ss')
      this.queryForm.endMatchTime = this.$moment(e[1]).format('YYYY-MM-DD HH:mm:ss')
    },

    showDetail (data) {
      this.dialogVisible = true
      if (this.queryForm.raceSchedule === 19) {
        const result = JSON.parse(data.result)
        console.log(result)
        this.resultList = result
      } else {
        const paperData = JSON.parse(data.result)
        var questionListData = [{ questionList: paperData.questionOneList }, { questionList: paperData.questionTwoList }, { questionList: paperData.questionThreeList }]
        questionListData.forEach((item, itemIndex) => {
          for (let index = 0; index < this.questionTypeListData.length; index++) {
            const element = this.questionTypeListData[index]
            if (itemIndex === 0 && element.flag === 'FORM_BLANK_ONE_SLOT') {
              item.questionData = element
            } else if (itemIndex === 1 && element.flag === 'FORM_ALL_SLOT_ANALOG') {
              item.questionData = element
            } else if (itemIndex === 2 && element.flag === 'FORM_ALL_SLOT_ANALOG') {
              item.questionData = element
            }
          }
        })
        this.questionListData = questionListData
      }
      this.totalScore = data.score
      this.userName = data.name
      this.phone = data.phone
    },
    showAddedScore () {
      // 全国总决选小组常规赛，得分相同的，答题时间越少分数越高，需要计算。
      this.level = ''
      this.addedScoreVisible = true
    },
    addedScoreCalc () {
      console.log(this.level)
      if (this.level !== '') {
        addedScore({
          season: this.queryForm.season,
          raceSchedule: this.queryForm.raceSchedule,
          level: this.level
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('提交成功')
            this.addedScoreVisible = false
            this.search()
          }
        })
      }
    },
    showRevive () {
      this.level = ''
      this.rate = '80'
      this.reviveVisible = true
    },
    setReviveFn () {
      if (this.level !== '' && ((this.radio === 2 && this.rate) || (this.radio === 1 && this.num))) {
        const form = {
          season: this.queryForm.season,
          raceSchedule: this.queryForm.raceSchedule,
          level: this.level
          // rate: this.rate
        }
        if (this.radio === 1) {
          form.num = this.num
        } else if (this.radio === 2) {
          form.rate = this.rate
        }
        setRevive(form).then(res => {
          if (res.code === 200) {
            this.$message.success('设置成功')
            this.reviveVisible = false
            this.getDataList()
          }
        })
      }
    },
    showInfo (data) {
      this.editForm = data
      console.log(this.editForm)
      this.infoVisible = true
    },
    submitInfo () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        console.log(this.editForm)
        updateTheOnlineFormAnswerInfo(this.editForm).then(res => {
          this.getDataList()
          this.infoVisible = false
        })
      })
    },
    raceScheduleFormatter (data) {
      var temp = '未设置'
      for (const key in this.raceScheduleList) {
        if (Object.hasOwnProperty.call(this.raceScheduleList, key)) {
          const element = this.raceScheduleList[key]
          if (Number(element.id) === Number(data.raceSchedule)) {
            temp = element.scheduleName
          }
        }
      }
      return temp
    },
    // 匹配系统信息
    normalDetail (rowData) {
      formMatchResultMate({ id: rowData.id }).then(res => {
        this.getDataList()
      })
    },
    untieDetail (rowData) {
      formMatchResultUnbinding({ id: rowData.id }).then(res => {
        this.getDataList()
      })
    },
    setPass () {
      this.$confirm('晋级结果将以晋级预期情况来设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$refs.setPassFormRef.validate(async valid => {
          if (!valid) return
          this.setPassForm.raceSchedule = this.queryForm.raceSchedule
          console.log('this.setPassForm', this.setPassForm)
          formPromotionBatch(this.setPassForm).then((res) => {
            console.log('queryModelPaperResultScore', res)
            this.setPassDialogVisible = false
            this.$message.success('设置成功')
            this.getDataList()
          }).catch((err) => {
            console.log('err', err)
          })
        })
      }).catch(() => { })
    },
    // 批量设置晋级弹窗关闭
    setPassDialogClose () {
      this.setPassSchoolList = []
      this.$refs.setPassFormRef.resetFields()
    },
    // 查看晋级预期
    queryPromotionExpectations () {
      this.$refs.setPassFormRef.validate(async valid => {
        console.log('valid', valid)
        if (!valid) return
        this.setPassForm.raceSchedule = this.queryForm.raceSchedule
        promotionBatchPreview(this.setPassForm).then(res => {
          this.setPassForm = { ...this.setPassForm, ...res.data }
        })
      })
    },
    // code
    copyScreenLink () {
      var input = document.createElement('input') // 创建input标签
      var location = window.location.origin
      input.value = `${location}/#/screen/home?code=${this.queryForm.matchCode}`
      document.body.appendChild(input) // 添加input标签
      input.select() // 选中input标签
      document.execCommand('copy') // 执行复制
      this.$message.success('复制成功') // 成功提示信息
      document.body.removeChild(input) // 移除input标签
    },
    handleToChangeStatus (status) {
      this.$http.post('formMatchCode/updateStatus', { code: this.queryForm.matchCode, status }).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.getStatusByCode()
        }
      })
    },
    getStatusByCode () {
      this.$http.get('formMatchCode/infoByCode', { params: { code: this.queryForm.matchCode } }).then(res => {
        if (res.code === 200) {
          const status = res.data.status
          // 1:未开始 2:比赛中 3:比赛结束 4:发布成绩
          this.tagStr = status === 1 ? '未开始' : status === 2 ? '比赛中' : status === 3 ? '比赛结束' : status === 4 ? '成绩已发布' : ''
          this.tagType = status === 1 ? 'info' : status === 2 ? '' : status === 3 ? 'danger' : status === 4 ? 'success' : ''
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .h1{
    color: #000;
    margin-top: 20px;
  }
  .h2{
    color: #000;
  }
  .total{
    font-size: 16px;
    color: #000;
    span{
      color: #409EFF;
    }
  }
  .font{
    margin-right: 12px;
  }

  .answer-img{
    width: 40px;
  }
  .selval{
    font-weight: bold;
  }
  .el-radio__original {
    display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
  }
  .question-item{
    .question-title{
      font-size: 14px;
      font-weight: bold;
      color: #000;
      width: 400px;
      text-align: center;
      margin: 0 auto 10px;
    }
    .a-title{
      color: #000;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 20px;
      .tag{
        margin-right: 10px;
      }
      .text{
        font-size: 14px;
      }
    }
    .question{
      color: #000;
      font-size: 14px;
    }
    .question-img{
      width: 260px;
    }
    .mb-6{
      margin-bottom: 6px;
    }
    .img-desc{
      display: block;
      width: 400px;
      margin: 0 auto 20px;
    }
    .flex{
      display: flex;
      justify-content: space-between;
    }
    .opt{
      display: flex;
      flex: 1;
    }
    .opt-index{
      margin-right: 4px;
    }
    .opt-img{
      width: 80px;
    }
    .questionDdescription{
      border: 1px dashed #409EFF;
      border-radius: 10px;
      padding: 20px;
      color: #000;
      line-height: 36px;
    }
  }
  .default{
    text-align: center;
    padding: 50px;
    color: #999;
    font-size: 14px;
  }
  .title{
    color: #000;
    font-size: 20px;
    text-align: center;
  }
  .title-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-top: 10px;
    div{
      margin-left: 30px;
    }
    span{
      color: #409EFF;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .title-flex1{
    // justify-content: flex-start;
    font-size: 16px;
  }
</style>
